import React, { Component } from "react";

class AdminPage extends Component {
  state = {};
  render() {
    return <></>;
  }
}

export default AdminPage;
